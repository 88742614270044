import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="turbo-select"
export default class extends Controller {
  change(event) {
    const url = event.target.selectedOptions[0].dataset.turboSelectUrlValue
    fetch(url, { method: "GET",  headers: { Accept: "text/vnd.turbo-stream.html" } })
      .then(r => r.text())
      .then(html => window.Turbo.renderStreamMessage(html))
  }
}
